// init action
const care_section = document.getElementById('care-section')
const facility_section = document.getElementById('facility-section')
const care_btn = document.querySelector('.section--1__action-box__btn--1')
const facility_btn = document.querySelector('.section--1__action-box__btn--2')
const contactButton = document.getElementById('contact-box')
const contact_section = document.getElementById('contact-section')

contactButton && contactButton.addEventListener('click', () => {
    contact_section.scrollIntoView({
        behavior: "smooth"
    })
})

care_btn && care_btn.addEventListener('click', () => {
    care_section.scrollIntoView({
        behavior: "smooth"
    })
})
facility_btn && facility_btn.addEventListener('click', () => {
    facility_section.scrollIntoView({
        behavior: "smooth"
    })
})
// form controller
function formController() {
    var confirmBtn = document.querySelector('button#confirm-btn');
    var returnBtn = document.querySelector('button#return-btn');
    var sendBtn = document.querySelector('button#send-btn');
    returnBtn.setAttribute('style', 'display: none');
    sendBtn.setAttribute('style', 'display: none');
    confirmBtn.addEventListener('click', handleConfirm);
    var values = {};
    var nameField = document.querySelector('input#name');
    var companyField = document.querySelector('input#company');
    var emailField = document.querySelector('input#email');
    var phoneField = document.querySelector('input#phone');
    var contentField = document.querySelector('textarea#content');
    var privacyField = document.querySelector('input#privacy');


    function handleConfirm(e) {

        var name = document.querySelector('input#name').value;
        var company = document.querySelector('input#company').value;
        var email = document.querySelector('input#email').value;
        var phone = document.querySelector('input#phone').value;
        var content = document.querySelector('textarea#content').value;
        var privacy = document.querySelector('input#privacy').checked;
        values = {
            name,
            company,
            email,
            phone,
            content,
            privacy
        }
        var formControllers = document.querySelectorAll('.chatbowl-lp-contact-form__controller');
        formControllers.forEach(el => {
            el.classList.remove('errors');
        })
        var errors = validationForm(values);

        if (Object.keys(errors).length !== 0) {
            Object.keys(errors).map(key => {
                const formController = document.querySelector(`#form-controller-${key}`)
                const error = document.querySelector(`#errors-${key}`)
                error.textContent = errors[key]
                formController.classList.add('errors')
            })
        } else {

            setDisableField('true')
            returnBtn.setAttribute('style', 'display: block')
            sendBtn.setAttribute('style', 'display: block')
            confirmBtn.setAttribute('style', 'display: none')
        }
    }
    function setDisableField(status) {
        nameField.setAttribute('disabled', status)
        companyField.setAttribute('disabled', status)
        emailField.setAttribute('disabled', status)
        phoneField.setAttribute('disabled', status)
        contentField.setAttribute('disabled', status)
        privacyField.setAttribute('disabled', status)

    }
    function validationForm(values) {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const phoneRegex = /^[0-9]*$/;
        var errors = {}
        if (!values.name) {
            errors.name = 'お名前が入力されていません'
        }
        if (values.email && !emailRegex.test(values.email)) {
            errors.email = 'メールアドレスは無効です'
        } else if (!values.email) {
            errors.email = 'メールアドレスが入力されていません'
        }
        if (!values.phone) {
            errors.phone = '電話番号が入力されていません'
        } else if (!phoneRegex.test(values.phone)) {
            errors.phone = '電話番号が半角数字で入力ください'
        }
        if (!values.content) {
            errors.content = 'お問い合わせ内容が入力されていません'
        }
        if (!values.privacy) {
            errors.privacy = 'プライバシーポリシーに同意する上で進めてください'
        }
        return errors
    }

    returnBtn.addEventListener('click', () => {
        nameField.removeAttribute('disabled')
        companyField.removeAttribute('disabled')
        emailField.removeAttribute('disabled')
        phoneField.removeAttribute('disabled')
        contentField.removeAttribute('disabled')
        privacyField.removeAttribute('disabled')
        returnBtn.setAttribute('style', 'display: none')
        sendBtn.setAttribute('style', 'display: none')
        confirmBtn.setAttribute('style', 'display: block')
    })


    sendBtn.addEventListener('click', async () => {


        sendBtn.classList.add('loading')

        // Detect if user is on IE browser
        var isIE = !!window.MSInputMethodContext && !!document.documentMode;

        if (isIE) {
            // Create Promise polyfill script tag
            var promiseScript = document.createElement("script");
            promiseScript.type = "text/javascript";
            promiseScript.src =
                "https://cdn.jsdelivr.net/npm/promise-polyfill@8.1.3/dist/polyfill.min.js";

            // Create Fetch polyfill script tag
            var fetchScript = document.createElement("script");
            fetchScript.type = "text/javascript";
            fetchScript.src =
                "https://cdn.jsdelivr.net/npm/whatwg-fetch@3.4.0/dist/fetch.umd.min.js";

            // Add polyfills to head element
            document.head.appendChild(promiseScript);
            document.head.appendChild(fetchScript);

            // Wait for the polyfills to load and run the function. 
            // We could have done this differently, 
            // but I've found it to work well for my use-cases
            setTimeout(function () {
                window
                    .fetch("https://app-api.chatbowl.jp/chatbowl/contact")
                    .then(function(res){
                        const contactForm = document.querySelector('.chatbowl-lp-contact-form')
                        contactForm.remove()
                        const completedForm = document.querySelector('.chatbowl-contact-completed')
                        completedForm.setAttribute('style', 'display: block')
                    })
                    .catch(handleErrors);
            }, 1000);
        } else {
             const response = await fetch("https://app-api.chatbowl.jp/chatbowl/contact", {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(values) // body data type must match "Content-Type" header
        });

        const result = response.json();

        if (result) {
            const contactForm = document.querySelector('.chatbowl-lp-contact-form')
            contactForm.remove()
            const completedForm = document.querySelector('.chatbowl-contact-completed')
            completedForm.setAttribute('style', 'display: block')
        }
        }
       

    })
}

formController()
const bg_animation = document.querySelector('.background-animation')
const logo_loading = document.querySelector('.logo_loading')
const loading_main = document.querySelector('.loading-main')
bg_animation && bg_animation.addEventListener('animationend', () => {
    logo_loading.classList.remove('fixed')
    logo_loading.classList.add('out')
})

logo_loading && logo_loading.addEventListener('animationend', (e) => {
    if (e.animationName === "logo-fading") {
        logo_loading.classList.remove("on")
        logo_loading.classList.add('toggle')
    }
})


var timeSkip = 0
const timeSkipInterval = setInterval(() => {
    timeSkip += 0.1;
    if (timeSkip === 3.2) {
        clearInterval(timeSkipInterval)
    }
}, 100);
window.onload = function () {
    if (timeSkip < 3.2) {
        setTimeout(() => {
            bg_animation.classList.add('on')
            logo_loading.classList.remove('toggle')
            logo_loading.classList.add('fixed')
            setTimeout(() => {
                firstLoading()
            }, 1800)
            clearInterval(timeSkipInterval)

        }, 3200 - timeSkip * 1000)
    } else {
        bg_animation.classList.add('on')
        logo_loading.classList.remove('toggle')
        logo_loading.classList.add('fixed')
        setTimeout(() => {
            firstLoading()
        }, 1800)
        clearInterval(timeSkipInterval)
    }
}
function firstLoading() {
    const main_content = document.querySelector('.body-content')
    main_content && main_content.classList.add('on')
    document.body.removeChild(loading_main)
    const embed = `<embed type="text/html" src="./assets/images/animation/data.html" style="width: 95%; height: 90%;">`
    const phone_frame = document.querySelector('.hero-section__content--animation-frame')
    phone_frame.insertAdjacentHTML('afterbegin', embed)
}

// 
// const element_animation = document.querySelectorAll('.scroll-animation')
// const section_content = document.querySelectorAll('.section-content')
// const obsCallback = function (entries, observer) {
//     const [entry] = entries
//     if (entry.isIntersecting) {
//         entry.target.classList.add('on')
//     }
// }
// const obsOptions = {
//     root: null,
//     threshold: 0.15,
   
// }
// const sectionObserver = new IntersectionObserver(obsCallback, obsOptions)
// section_content.forEach(section => {
//     sectionObserver.observe(section)
// })

const heroImages = document.querySelectorAll('.hero-img')
if (heroImages.length > 0) {
    setTimeout(() => {
        resetSrc()
        toggleImageSrc(heroImages[0])
        toggleImageSrc(heroImages[4])
    }, 0)
    setTimeout(() => {

        resetSrc()
        toggleImageSrc(heroImages[1])
        toggleImageSrc(heroImages[5])
    }, 2000)
    setTimeout(() => {
        resetSrc()
        toggleImageSrc(heroImages[2])
        toggleImageSrc(heroImages[3])

    }, 4000)
    setInterval(() => {

        setTimeout(() => {
            resetSrc()
            toggleImageSrc(heroImages[0])
            toggleImageSrc(heroImages[4])
        }, 0)

        setTimeout(() => {

            resetSrc()
            toggleImageSrc(heroImages[1])
            toggleImageSrc(heroImages[5])
        }, 2000)

        setTimeout(() => {
            resetSrc()
            toggleImageSrc(heroImages[2])
            toggleImageSrc(heroImages[3])

        }, 4000)
    }, 6000)
}



function resetSrc() {
    heroImages.forEach(el => {
        const src1 = el.dataset.srcOne
        el.setAttribute('src', src1)
    })

}

function toggleImageSrc(el) {
    const src1 = el.dataset.srcOne
    const src2 = el.dataset.srcTwo
    var currentSrc = el.getAttribute('src')
    if (currentSrc === src1) {
        currentSrc = src2
    } else {
        currentSrc = src1
    }
    el.setAttribute('src', currentSrc)
}

